<template>
  <div>
    <full-page ref="fullpage" :options="options">
      <div class="section page1-wrap">
        <div class="box1">
          <img src="../../assets/images/banner1.png" alt="" />
        </div>
      </div>
      <div class="section page2-wrap">
        <div class="box2">
          <img src="../../assets/images/product11.png" alt="" />
        </div>
      </div>
      <div class="section page3-wrap">
        <div class="box3">
          <img src="../../assets/images/product22.png" alt="" />
        </div>
      </div>
      <div class="section page4-wrap">
        <div class="box4">
          <img src="../../assets/images/product33.png" alt="" />
        </div>
      </div>
      <div class="section page5-wrap">
        <div class="box5">
          <img src="../../assets/images/product44.png" alt="" />
        </div>
      </div>
      <div class="section page6-wrap">
        <div class="box6">
          <img src="../../assets/images/product55.png" alt="" />
        </div>
      </div>
      <div class="section page7-wrap">
        <div class="box7">
          <img src="../../assets/images/product66.png" alt="" />
        </div>
      </div>
      <div class="section page8-wrap">
        <div class="box8">
          <img src="../../assets/images/product77.png" alt="" />
        </div>
      </div>
      <div class="section page9-wrap">
        <div class="box9">
          <Footer></Footer>
        </div>
      </div>
    </full-page>
    <div class="sidebar4 flex" @click="backTop">
      <img src="../../assets/images/sidebar4.png" />
    </div>
  </div>
</template>
<script>
// import { BusEmit } from "@/utils/eventBus.js";
import Footer from '../../layout/components/footer.vue'
export default {
  components: {
    Footer
  },
  methods: {
    // handc() {
    //   BusEmit("Top", "setBlackBg");
    // },
    // setWhite() {
    //   BusEmit("Top", "setWhiteBg");
    // },
    backTop() {
      this.$refs.fullpage.api.moveTo(1, 0)
    }
  },
  mounted() {
    // this.handc()
  },
  name: '',
  data() {
    return {
      options: {
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        afterLoad: this.afterLoad,
        scrollOverflow: true,
        scrollBar: false,
        menu: '#menu'
        // sectionsColor:[
        // ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  width: 1920px;
  margin: 0 auto;
  img {
    width: 1920px;
  }
}
.sidebar4 {
  position: fixed;
  z-index: 1000;
  width: 50px;
  right: 60px;
  bottom: 66px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 50%;
  background-color: $blue;
  border: 2px solid #fff;
  cursor: pointer;
}
</style>
